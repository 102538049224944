import React, { ReactNode, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import LegacyCarousel from '../../../LegacyCarousel'
import MapsListCard from '../../../cards/MapsListCard'
import { useInView } from 'react-intersection-observer'
import { HomepageProps } from '../../../../pages'
import Image from 'next/image'
import NewCarousel from '../../../NewCarousel'

type Props = {
    classes: any
    promotions: HomepageProps['promotionsData']
    isMobile: boolean
    lang: any
}

const SmartMoveSection = (props: Props) => {
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    })

    const renderHotPromotions = useCallback(() => {
        let output: ReactNode[] = []

        if (props.promotions && props.promotions.length) {
            props.promotions.forEach((promotion, idx) => {
                output.push(
                    <div key={idx} className="w-full rounded-md pr-3 md:px-2 md:w-1/3 lg:w-1/3 flex-shrink-0 space-y-4 > * mb-4 lg:mb-0">
                        <MapsListCard
                            key={idx}
                            lang={props.lang}
                            {...promotion}
                            photos={promotion.thumbnail}
                            // star_count={promotion.is_star}
                            title={promotion.name}
                            property_location={promotion.location}
                            url={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/project/${promotion.project_slug}/cluster/${promotion.slug}`}
                            url2={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/project/${promotion.project_slug}`}
                            containerClasses="mb-5 border border-gray-e6"
                            // homepage
                            doubleDream
                            mapsPage
                        // withWAButton
                        // onWAButtonClick={() => handleWAButtonClick(promotions[index])}
                        />
                    </div>
                )
            })
        }

        return output
    }, [props.promotions])

    return (
        <div className='mb-12'>
            <div
                className={`relative mb-12 ${props.classes['double-dream-bg']}`}
            >
                <div className='absolute top-0 left-0 right-0 bottom-0 w-full hidden lg:block' style={{ zIndex: -1 }}>
                    <Image
                        src={'/static/images/smart-move/Smartmove-Desktop.jpg'}
                        alt="infinite living background"
                        layout='fill'
                        className='object-contain object-top'
                    />
                </div>
                <div className='absolute inset-0 w-full lg:hidden' style={{ zIndex: -1 }}>
                    <Image
                        src={'/static/images/smart-move/Smartmove-Mobile.jpg'}
                        alt="infinite living background"
                        layout='fill'
                        className='object-contain object-top'
                    />
                </div>
                <div className={`container flex flex-col`}>
                    <div className='md:mt-8 mt-20 w-5/6 md:mx-auto md:w-1/3'>
                        <p className="text-xs lg:text-base text-white md:text-black-48 mb-2 md:mb-6 text-left">
                            Sinar Mas Land's newest promo, offering free BPHTB, subsidized financing and taxes, and other<br /> incentives for your new home.
                        </p>
                    </div>
                    <div className='md:absolute mb-4' style={props.isMobile ? {} : { left: '4%', top: '16%' }}>
                        <a href="/infiniteliving" className={`block rounded max-w-300 focus:shadow-outline py-3 lg:py-2 px-4 lg:px-5 bg-main text-white text-base font-semibold focus:outline-none items-center justify-center capitalize `}>
                            <div
                                className="flex items-center justify-center"
                                style={{ verticalAlign: 'middle' }}
                            >
                                <span className='text-sm'>Explore More</span>
                                <FontAwesomeIcon icon={faLongArrowAltRight} width="0" className="ml-2 my-auto" />
                            </div>
                        </a>
                    </div>
                    <div className='md:flex pt-32 md:pt-0'>
                        <div className='w-full md:ml-auto md:w-3/4 md:pl-3'>
                            <div ref={ref} className="p-5 rounded-md overflow-hidden md:overflow-visible pr-0 md:pt-4 md:px-6 md:pb-2 bg-white shadow-homepage-item relative">
                                {
                                    props.isMobile ?
                                        <div className='absolute z-3 top-0 bottom-0 right-0 w-1/6 bg-gradient-to-l from-white to-transparent'></div>
                                        : null
                                }
                                {
                                    inView ?
                                        <NewCarousel
                                            show={props.isMobile ? 1 : 3}
                                            // isRepeatedProps={['xs', 'sm', 'md'].includes(breakpoint)}
                                            sliderClass="pr-12 md:px-0"
                                            sliderOffset={props.isMobile ? 48 : 0}
                                            withIndicator
                                            indicatorSpacing="pt-0 pb-2 md:py-2"
                                            indicatorShow={1}
                                            leftArrow={prev =>
                                                <div className="hidden lg:block absolute left-0 z-1 transform top-1/2 -translate-y-1/2 -translate-x-12">
                                                    <button onClick={prev} className="bg-main hover:bg-main-dark active:bg-main-dark w-47px h-47px rounded outline-none focus:outline-none transition-colors duration-150 ease-linear flex items-center justify-center">
                                                        <FontAwesomeIcon icon={faAngleLeft} width="0" className="text-white" size="lg" />
                                                    </button>
                                                </div>
                                            }
                                            rightArrow={next =>
                                                <div className="hidden lg:block absolute right-0 z-1 transform top-1/2 -translate-y-1/2 translate-x-12">
                                                    <button onClick={next} className="bg-main hover:bg-main-dark active:bg-main-dark w-47px h-47px rounded outline-none focus:outline-none transition-colors duration-150 ease-linear flex items-center justify-center">
                                                        <FontAwesomeIcon icon={faAngleRight} width="0" className="text-white" size="lg" />
                                                    </button>
                                                </div>
                                            }
                                        >
                                            {renderHotPromotions()}
                                        </NewCarousel>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SmartMoveSection